@import 'src/variables';

/* Header Font */
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');

/* Body Font */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

//@import "~@ng-select/ng-select/themes/default.theme.css";

/* Override bootstrap */
$theme-colors: (
    primary: $color-primary,
    danger: $color-danger,
    warning: $color-warning,
    success: $color-success,
    info: $color-info,
);

$form-check-input-checked-bg-color: $color-primary;
$form-check-input-checked-border-color: $color-primary;

$enable-negative-margins: true;

@import 'bootstrap';

html,
body {
    overflow-x: hidden;
}

btn {
    font-size: 16px;
}

.cursor {
    cursor: pointer;
}

.floating-whatsapp-button {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 20px;
    right: 20px;
    background-color: #25d366;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #333;
    z-index: 100;

    i {
        margin-top: 15px;
        color: white;
        margin-left: 2px;
    }
}

.table-sm {
    td {
        font-size: 13px;
        padding: 1px 5px;
        font-family: $body-font;
    }

    th {
        font-weight: 700;
        font-size: 13px;
        line-height: 1;
    }
}

.text-accent {
    color: $color-accent;
}

.bg-dark {
    background-color: $color-secondary;
}

.bg-yellow {
    background-color: $color-primary;
    color: black;
}

.bg-purple {
    background-color: $color-purple;
}

.text-blue {
    color: $color-secondary;
}

.bg-blue {
    background-color: $color-secondary;
}

.btn-outline-blue {
    border: 1px solid $color-secondary;
    color: $color-secondary;
}

.bg-grey {
    background-color: #f8f8f8;
}

footer h6,
footer a,
footer div {
    color: $color-white;
}

footer p,
footer div,
footer a {
    font-weight: 300;
    font-size: 14px;
}

footer a:hover {
    color: $color-primary;
}

.header-part-bold {
    font-weight: 600;
}

.header-part-light {
    font-weight: 300;
}

.invalid-feedback {
    display: initial;
}

.btn {
    border-radius: 10px;
    padding: 10px 25px;
}

.btn-line-1 {
    font-size: 18px;
    font-weight: 600;
}
.btn-line-2 {
    font-size: 14px;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
    color: $color-title;
}

.btn-primary:hover {
    color: white;
}

.btn-primary:disabled,
.btn-primary.disabled {
    background-color: #c3c3c3;
    border-color: #c3c3c3;
}

.btn-check:checked + .btn-outline-primary,
.btn-check:active + .btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show {
    color: $color-white;
}

.nav-pills .nav-link {
    background: $color-background;
    border: 1px solid $color-background;
    color: $color-body;
    font-weight: 600;
    border-radius: 10px;
}

.nav-pills > .nav-link,
.nav-pills .nav-item {
    padding: 0 2.5px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    background-color: $color-white;
    border: 1px solid $color-primary;
    color: $color-primary;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1.5rem;
    }
}

a,
a:hover,
a:active {
    cursor: pointer;
    color: $color-secondary;
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $header-font;
    font-weight: 500;
    color: $color-title;
}

div,
p,
label {
    font-family: $body-font;
    line-height: 25px;
    font-weight: 400;
    color: $color-body;
}

label {
    font-weight: 600;
    line-height: 30px;
}

.divider {
    height: 1px;
    background-color: $color-border;
}

.rounded-image,
.corner-radius {
    border-radius: $corner-radius;
}

.round-image {
    border-radius: 50%;
}

.side-menu {
    display: inline-block;
    vertical-align: top;
    border-right: 1px solid $color-border;
    min-height: calc(100vh - 80px);
    padding: 25px 10px;
    background-color: $color-white;
    width: 400px;
}

.side-menu .nav-pills {
    padding: 0 15px;
}

.side-menu-content {
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 400px);
    min-height: calc(100vh - 80px);
    background-color: $color-background;
    padding: 25px 10px;
}

.dropzone-wrapper {
    border: 2px dashed $color-primary;
    position: relative;
    height: 150px;
    line-height: 150px;
    border-radius: 15px;
    text-align: center;
}

.dropzone-desc {
    line-height: 150px;
    display: inline-block;
    color: $color-primary;
}

.dropzone-desc h4 {
    color: $color-primary;
}

.dropzone,
.dropzone:focus {
    position: absolute;
    outline: none !important;
    width: 100%;
    height: 150px;
    cursor: pointer;
    opacity: 0;
}

.dropzone-wrapper:hover,
.dropzone-wrapper.dragover,
.dropzone-container:hover .dropzone-wrapper,
.dropzone-container.dragover .dropzone-wrapper {
    background: #ecfffc;
    cursor: pointer;
}

.dropzone-wrapper-small {
    height: 100px;
    line-height: 100px;
}

span.undefined {
    color: #bebebe;
    font-style: italic;
}

.vwcenter-parent {
    display: flex;
}

.vwcenter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

#toast-container {
    top: 100px;
    border-radius: $corner-radius;
    overflow: hidden;
    box-shadow: $box-shadow;
}

#toast-container > .toast:before {
    position: fixed;
    font-family: 'FontAwesome', sans-serif;
    font-size: 24px;
    line-height: 24px;
    float: left;
    color: $color-white;
    padding-right: 0.5em;
    margin: auto 0.5em auto -1.5em;
}

.toast-message {
    color: $color-white;
}

.toast-success,
.toast-error,
.toast-info,
.toast-warning {
    background-color: $color-primary;
}

.toast-container .ngx-toastr {
    margin: 0;
}

.toast-container .toast {
    background-color: $color-primary;
    color: $color-white;
    border-radius: 15px;
    padding: 20px 20px 20px 60px;
    width: 400px;
    font-weight: 600;
}

#toast-container > .toast:before {
    color: $color-white;
}

#toast-container > div {
    opacity: 1;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    border: 1px solid $color-border;
}

.toast-title {
    margin-bottom: 8px;
    margin-top: 3px;
    color: $color-white;
    font-size: 16pt;
}

.gallery-carousel {
    position: absolute;
    overflow: hidden;
    z-index: 5;
    width: 100%;
    top: 0;
    bottom: 0;
}

.no-image-icon {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 4;
    bottom: 0;
    top: 0;
    text-align: center;
}

.no-image-icon i {
    font-size: 100px;
    color: #e1e1e1;
    vertical-align: middle;
    top: calc(50% - 50px);
    position: absolute;
    left: 0;
    right: 0;
}

.image-load-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 25px;
    z-index: 2;
    text-align: center;
}

.image-load-container i {
    font-size: 20px;
}

.icon-button {
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    background: $color-background;
    border: 1px solid $color-border;
    border-radius: 5px;
    display: inline-block;
}

.icon-button:hover {
    cursor: pointer;
    box-shadow: $box-shadow;
}

.side-menu-button {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    //margin-left: -15px;
    border: 1px solid #bababa;
    border-radius: 5px;
    margin: 5px;
}

.side-menu-button:hover {
    cursor: pointer;
}

.sticky-buttons {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background: $color-white;
    box-shadow: $box-shadow;
    z-index: 10;
}

.icon-wrapper {
    background: #3f3f3f;
    color: white;
    border-radius: 5px;
    width: 22px;
    height: 22px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    margin: 0px 2px;

    &.tel {
        background: #2576d3;
    }

    &.whatsapp {
        background: #25d366;
    }

    &.email {
        background: #a84230;
    }
}

label.required:after {
    content: ' *';
    color: $danger;
}

span.new-promoted {
    color: $danger;
    text-transform: uppercase;
    font-size: 10px;
    vertical-align: top;
    font-weight: bolder;
}

.admin-only {
    background: #ffebff;
    border: 1px solid #9a4bc0;
    border-radius: 5px;
    padding: 10px;
}
.text-admin-only {
    color: #9a4bc0;
    font-weight: bold;
}

.bg-whatsapp {
    background: #25d366;
}

.bg-dark-blue {
    background: $color-secondary;
}

.bg-payfast {
    background: #bc1212;
}

.bg-paygate {
    background: #0f54a6;
}

.page-loading-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    position: fixed; /* or 'absolute', depending on your layout */
    top: 0;
    left: 0;
    z-index: 1000; /* Ensure it's above other content */
    font-size: 40px;
}

.small {
    line-height: 1.3;
}

.swiper-wrapper {
    margin-bottom: 0px !important;
}

@media (max-width: 991px) {
    .side-menu {
        position: fixed;
        z-index: 8;
        transition: all 0.3s;
        height: calc(100vh - 80px);
        overflow-y: scroll;
    }
    .side-menu-content {
        width: 100%;
    }
    .side-menu-collapsed {
        transform: translateX(-400px);
    }

    .side-menu-expanded {
        transform: translateX(0px);
    }

    // Elfsight reviews
    .BadgeContainer__Inner-sc-pa6z2-0 {
        display: none !important;
    }
}

@media (max-width: 768px) {
    #invoice-print-page {
        #row {
            max-height: 350px;
        }

        #outer-container {
            min-width: 250%;
            transform: scale(0.4);
            transform-origin: top left;
            max-height: 800px;

            #print-container {
                height: 800px;
            }
        }
    }
}

tr.selected {
    background-color: #4bddff;
}

.email-header {
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.email-content {
    border: 1px solid #ccc;
    padding: 20px;
    background-color: #ffffff;
    a {
        color: blue !important;
        text-decoration: underline !important;
    }

    p {
        margin-bottom: 10px;
    }
}

.logs-container {
    max-width: 1000px;
    margin: 0px auto;
    margin-top: 20px;

    ul {
        list-style-type: none;
        padding: 0px;

        li {
            margin-bottom: 5px;
        }
    }
}

.bg-payfast {
    background-color: #bc1212;
}
